<template>
  <section>
    <h1 class="sr-only">Sponsored video</h1>
    <template v-if="videoCode">
      <YTPlayer
        :video-code="videoCode"
        autoplay
        class="fixed inset-0 w-full h-full"
        @end="navigateBack"
        v-if="!isOurVideo"
      />
      <VideoComponent
        v-else-if="videoCode"
        autoplay
        :src="videoCode"
        @ended="navigateBack"
        controls
        class="absolute top-0 left-0 w-full h-full object-contain --reset-flip"
      />
    </template>

    <v-btn
      color="red"
      dark
      class="fixed"
      style="right: 8px; bottom: 80px"
      @click="navigateBack"
      >Skip</v-btn
    >
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import Vue from "vue"
import isVideo from "is-video"

import { InterimVideoStorage } from "@/helpers"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"
export default Vue.extend({
  name: "InterimVideo",
  components: {
    VideoComponent,
    YTPlayer: () => import("@/components/VideoPlayers/YTPlayer.vue")
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      path: null
    }
  },
  computed: {
    ...mapGetters(["game", "gameID"]),
    videoCode() {
      return this.game?.interimVideoCode
    },
    isOurVideo() {
      return isVideo(this.videoCode)
    }
  },
  created() {
    this.path = this.$route?.query?.["path"]
    if (!this.videoCode) {
      return this.navigateBack()
    }

    setTimeout(() => {
      InterimVideoStorage.add(this.gameID)
    }, 2000)
  },
  methods: {
    navigateBack() {
      if (this.path) this.$navigator.navigateTo(`/${this.path}/${this.id}`)
      else this.$navigator.navigateTo(`/pickteams/${this.id}`)
    }
  }
})
</script>
